
.app{
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height:100vh;
  background-color: black;
}

.display-div{
  border: 2px solid white;
  color: white;
  border-radius: 5px;
  height: 80px;
  font-size: 50px;
  width: 50%;
  text-align: right;
  overflow-x: auto;
  overflow-y: hidden;
  margin-bottom: 10px;
}

.input-pad{
  display: flex;
  flex-direction: row;
  width: 50%;
  border: 1px solid black;
  border-radius: 5px;
  margin-bottom: 10px;
}

.number-pad{
  display: grid;
  width: 80%;
  grid-template-columns: 1fr 1fr 1fr;
  gap:10px;
  font-size: 50px;
  font-weight: bold;
  border:1px solid black;
  border-right: none;
  padding:10px;
}

.grid-item{
  display: flex;
  justify-content: center;
  border:1px solid black;
  background-color: darkgray;
  border-radius: 5px;
}

.active-grid-item{
  background-color: white;
  color: black;
  display: flex;
  justify-content: center;
  border-radius: 5px;
}

.grid-item:hover{
  background-color: grey;
  color: white;
}

.operator-pad>.grid-item{
  background-color: orange;
  color: white;

}

.operator-pad{
  width: 20%;
  display: grid;
  gap: 10px;
  font-size: 50px;
  font-weight: bold;
  border:1px solid black;
  padding:10px;
}

.equals-div{
  border: 1px solid black;
  font-size: 50px;
  font-weight: bold;
  width: 50%;
  border-radius: 5px;
}
